import React, { useRef, useState } from "react";
import { Transition } from "@headlessui/react";
import { ArrowSmallDownIcon } from "@heroicons/react/24/outline";

export const Accordion = ({ content, children }) => {
  const ref = useRef(null);
  const [shown, setShown] = useState(false);

  const captureHeight = () =>
    ref.current && (ref.current.style.height = `${ref.current.clientHeight}px`);
  const clearHeight = () => ref.current && (ref.current.style.height = "");

  const [isOpen, setOpen] = useState(false);
  return (
    <div className="mt-6 border-t border-gray-200 pt-6">
      <div>
        <dt className="text-lg leading-7">
          <button
            onClick={() => setOpen(!isOpen)}
            aria-label="Show answer"
            className="text-left w-full font-bold flex justify-between items-start text-gray-400 focus:outline-none focus:text-gray-900"
          >
            <span className=" prose prose-indigo prose-lg text-gray-700">
              {content.title}
            </span>
            <span className="ml-6 h-7 flex items-center">
              <svg
                className={`${
                  isOpen ? "-rotate-180" : "rotate-0"
                } h-6 w-6 transform`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </span>
          </button>
        </dt>
        <Transition
          show={isOpen}
          ref={ref}
          enter="transition-[height] duration-300 overflow-hidden"
          enterFrom="!h-0"
          beforeEnter={captureHeight}
          afterEnter={clearHeight}
          leave="transition-[height] duration-300 overflow-hidden"
          leaveTo="!h-0"
          beforeLeave={captureHeight}
          afterLeave={clearHeight}
        >
          {(ref) => (
            <dd ref={ref} className="mt-2 pr-12">
              {children}

              {content.asHtml ? (
                <p
                  className="text-base leading-6 text-gray-700 whitespace-pre-wrap break-words"
                  dangerouslySetInnerHTML={{ __html: content.text }}
                ></p>
              ) : (
                <p className="text-base leading-6 text-gray-700 whitespace-pre-wrap break-words">
                  {content.text}
                </p>
              )}
            </dd>
          )}
        </Transition>
      </div>
    </div>
  );
};
