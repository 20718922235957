import React from "react";
import Layout from "../components/layout";
import { PageTitle } from "../components/Common/PageTitle";
import { JoinNewsLetterButton } from "../components/Common/NewsLetterPlug";
import ReactTooltip from "react-tooltip";

import ReactCountryFlag from "react-country-flag";
import { IoMapSharp } from "@react-icons/all-files/io5/IoMapSharp";
import { SiInstagram } from "@react-icons/all-files/si/SiInstagram";
import { FiExternalLink } from "@react-icons/all-files/fi/FiExternalLink";
import { AiOutlineUnorderedList } from "@react-icons/all-files/ai/AiOutlineUnorderedList";
import { Accordion } from "../components/Accordion/Accordion";

/**
 *
 *     {
 *       country: 'gb',
 *       area: '(Rhode Island)',
 *       name: 'JewelCast',
 *       website: 'https://www.jewelcast.co.uk/',
 *       location: 'https://www.google.com/maps/place/Sturm+KG/@48.3413305,14.1643863,15z/data=!4m5!3m4!1s0x0:0x327a59a0ac92cc50!8m2!3d48.3413305!4d14.1643863',
 *       address: 'No. 15, Lide St, Zhonghe District, Taiwan 235'
 *       directory: 'https://www.yourlocal.ie/company/379251006312448',
 *     },
 *
 */
const companies = {
  Europe: [
    {
      country: "gb",
      name: "Cousins UK",
      website: "https://www.cousinsuk.com/",
    },
    {
      country: "gb",
      name: "Cooksongold",
      website: "https://www.cooksongold.com/",
    },
    {
      country: "gb",
      name: "HS Walsh",
      website: "https://www.hswalsh.com/",
    },
    {
      country: "gb",
      name: "Proops Brothers",
      website: "https://www.proopsbrothers.com/",
    },
    {
      country: "gb",
      name: "Tools n Tools UK",
      website: "https://www.toolsntoolsuk.co.uk/",
    },
    {
      country: "at",
      name: "J Felber",
      website: "https://www.j-felber.at/",
    },
    {
      country: "at",
      name: "Baiers Enkel",
      website: "http://www.baiers.at/",
    },
    {
      country: "de",
      name: "Fischer",
      website: "https://goldschmiedebedarf.de/en/",
    },
    {
      country: "de",
      name: "Boley",
      website: "https://boley.de/en/shop/6.tools",
    },
    {
      country: "be",
      name: "Davtools",
      website: "https://www.davtools.be/",
    },
    {
      country: "be",
      name: "Buko",
      website: "https://www.bukowebshop.com/",
    },
    {
      country: "ch",
      name: "Bijoutil",
      website: "https://www.bijoutil.ch/en/",
    },
    {
      country: "ch",
      name: "Asic",
      website: "https://asic-sa.com/",
    },
    {
      country: "nl",
      name: "Bijou Moderne",
      website: "https://www.bijoumoderne.nl/",
    },
    {
      country: "nl",
      name: "Herens & Herens",
      website: "https://herens.nl/",
    },
    {
      country: "fr",
      name: "Laval",
      website: "https://www.laval-europe.com/en/",
    },
    {
      country: "fr",
      name: "Pouget Pellerin",
      website: "http://www.pouget-pellerin.com/index.php",
    },
    {
      country: "pl",
      name: "Kociuba",
      website: "https://www.wkociuba.pl/",
    },
    {
      country: "pl",
      name: "Pat Tools",
      website: "https://pat-tools.com/",
    },
    {
      country: "se",
      name: "NSG",
      website: "https://shop.nsg.se/en/",
    },
    {
      country: "se",
      name: "Sargenta",
      website: "https://www.sargenta.se/#top",
    },
    {
      country: "se",
      name: "Brotech",
      website: "https://brotech.se/kategori/laboratory/wax-products",
    },
    {
      country: "ie",
      name: "NN Equipment",
      website: "https://www.nnequipment.com/",
    },
    {
      country: "it",
      name: "Mealli",
      website: "https://shop.mealli.it/index.php",
    },
    {
      country: "it",
      name: "Dal Trozzo",
      website: "https://www.luigidaltrozzo.it/home",
    },
    {
      country: "it",
      name: "Pandora Alloys",
      website: "https://www.pandoralloys.com/en/",
    },
    {
      country: "it",
      name: "Pelusi",
      website: "https://www.pelusi.com/",
    },
    {
      country: "es",
      name: "Benmayor",
      website: "https://jewelry.benmayor.com/gb/",
    },
    {
      country: "es",
      name: "Girbau",
      website: "https://girbaubcn.es/",
    },
    {
      country: "es",
      name: "Hispana",
      website: "http://www.hispanaspain.com/index.htm",
    },
    {
      country: "pt",
      name: "Lingold",
      website: "https://lingold.pt/",
    },
    {
      country: "cz",
      name: "Advantage",
      website: "https://eshop.advantage-fl.cz/",
    },
    {
      country: "ro",
      name: "Artkimia",
      website: "https://en.artkimia.com/",
    },
    {
      country: "ro",
      name: "Jewel District",
      website: "https://jeweldistrict.ro/",
    },
    {
      country: "ro",
      name: "Carrus Expert",
      website: "https://carrusexpert.ro/",
    },
  ],
  Asia: [
    {
      country: "HK",
      name: "Wing Wo Hong",
      website: "https://www.wingwohonghk.com/",
    },
  ],
  Oceania: [
    {
      country: "nz",
      name: "Regal",
      website: "https://www.regal.co.nz/",
    },
    {
      country: "nz",
      name: "Warburtons",
      website: "https://www.warburtons.co.nz/",
    },
    {
      country: "au",
      name: "Australian Jewellers Supplies",
      website: "https://www.jewellerssupplies.com.au/",
    },
    {
      country: "au",
      name: "Koodak",
      website: "https://koodak.com.au/",
    },
  ],
  ["North America"]: [
    {
      country: "us",
      name: "Contenti",
      website: "https://contenti.com/",
    },
    {
      country: "us",
      name: "Rio Grande",
      website: "https://www.riogrande.com/",
    },
    {
      country: "us",
      name: "Otto Frei",
      website: "https://www.ottofrei.com/",
    },
    {
      country: "us",
      name: "Gesswein",
      website: "https://www.gesswein.com/",
    },
    {
      country: "us",
      name: "Seattle Findings",
      website: "https://www.seattlefindings.com/",
    },
    {
      country: "us",
      name: "Shor International",
      website: "https://www.ishor.com/",
    },
    {
      country: "us",
      name: "Cool Tools",
      website: "https://www.cooltools.us/",
    },
    {
      country: "us",
      name: "Esslinger",
      website: "https://www.esslinger.com/",
    },
    {
      country: "us",
      name: "C R Hill Company",
      website: "https://www.crhill.com/",
    },
    {
      country: "us",
      name: "Stuller",
      website: "https://www.stuller.com/",
    },
    {
      country: "us",
      name: "A&A Jewelry Supply",
      website: "https://www.aajewelry.com/",
    },
    {
      country: "us",
      name: "Pepetools",
      website: "https://pepetools.com/",
    },
    {
      country: "us",
      name: "Romanoff",
      website: "https://www.romanoff.com/",
    },
    {
      country: "us",
      name: "FDJtool",
      website: "https://www.fdjtool.com/",
    },
    {
      country: "us",
      name: "Kingsley North",
      website: "https://kingsleynorth.com/",
    },
    {
      country: "ca",
      name: "Habsons Jewellers Supplies",
      website: "http://www.habsonssupplies.com/",
    },
    {
      country: "ca",
      name: "Lacy West",
      website: "https://lacywest.com/",
    },
    {
      country: "ca",
      name: "Bedrock Supply",
      website: "http://bedrocksupply.com/index.php?main_page=index",
    },
    {
      country: "ca",
      name: "Gesswein",
      website: "https://www.gessweincanada.com/",
    },
    {
      country: "ca",
      name: "Silver Anvil",
      website: "https://www.silveranvilrockandgem.ca/",
    },
    {
      country: "ca",
      name: "European Design Jewellery",
      website: "https://www.europeandesign.ca/",
    },
    {
      country: "ca",
      name: "Yu Yo",
      website: "https://yuyo.ca/",
    },
    {
      country: "mx",
      name: "Doxa",
      website: "https://www.doxa.com.mx/",
    },
    {
      country: "mx",
      name: "Diamantex",
      website: "https://diamantex.com/",
    },
  ],
  Africa: [
    {
      country: "za",
      name: "Cape Tools & jewellery supplies",
      website: "https://www.capewatch.co.za/",
    },
  ],
};

const CompanyListItem = ({ company }) => (
  <li className="bg-white" key={company.name}>
    <div className="px-6 py-5 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
      <div className="flex-shrink-0">
        <ReactCountryFlag
          countryCode={company.country}
          svg
          cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/"
          cdnSuffix="svg"
          style={{
            fontSize: "2em",
            lineHeight: "2em",
          }}
        />
      </div>
      <div className="flex-1 min-w-0">
        {company.area && company.area}
        {company.website ? (
          <a
            href={company.website}
            className="focus:outline-none"
            target="_blank"
            rel="noreferrer noopener"
          >
            <p className="text-sm font-medium text-gray-900">{company.name}</p>
          </a>
        ) : (
          <div>
            <p className="text-sm font-medium text-gray-900">{company.name}</p>
            <p className="text-sm text-gray-500 truncate">(No website)</p>
          </div>
        )}
      </div>
      <div className="flex flex-col">
        {company.location && (
          <div className="flex-1 inline-flex items-center">
            <a
              href={company.location}
              target="_blank"
              rel="noreferrer noopener"
            >
              <div className="flex">
                <div className="flex-shrink-0">
                  <IoMapSharp size={"1.5em"} />
                </div>
                <div className="ml-3 text-base leading-6 text-gray-500">
                  <p>(Google Maps)</p>
                </div>
              </div>
            </a>
          </div>
        )}
        {company.website && (
          <div className="flex-1 inline-flex items-center">
            <a href={company.website} target="_blank" rel="noreferrer noopener">
              <div className="flex">
                <div className="flex-shrink-0">
                  <FiExternalLink size={"1.5em"} />
                </div>
                <div className="ml-3 text-base leading-6 text-gray-500">
                  <p>Website</p>
                </div>
              </div>
            </a>
          </div>
        )}
        {company.instagram && (
          <div>
            <a
              href={`https://instagram.com/${company.instagram}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <div className="flex">
                <div className="flex-shrink-0">
                  <SiInstagram size={"1.5em"} />
                </div>
                <div className="ml-3 text-base leading-6 text-gray-500">
                  <p>Instagram</p>
                </div>
              </div>
            </a>
          </div>
        )}
        {company.directory && (
          <div>
            <a
              href={company.directory}
              target="_blank"
              rel="noreferrer noopener"
            >
              <div className="flex">
                <div className="flex-shrink-o">
                  <AiOutlineUnorderedList size={"1.5em"} />
                </div>
                <div className="ml-3 text-base leading-6 text-gray-500">
                  <p>Directory Page</p>
                </div>
              </div>
            </a>
          </div>
        )}
      </div>
    </div>
  </li>
);

const CastingCompanies = () => {
  return (
    <Layout full>
      <div className="relative pt-16 pb-6 px-4 sm:px-6 lg:pt-24 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <PageTitle title="Tools and Supplies" subtitle="" />
        </div>
      </div>

      <div className="bg-blue-200  md:mt-4">
        <div className="max-w-4xl mx-auto py-6 px-4 sm:px-4 lg:px-4">
          <p className="text-xl text-gray-700 tracking-wide text-center whitespace-pre-wrap mb-4">
            Want to buy wax or tools?
          </p>
          <p className="text-xl text-gray-700 tracking-wide text-left whitespace-pre-wrap">
            Here’s a list of jewellery tool suppliers from around the world.
            I’ve personally used a few of these and the others have been
            recommended to me. I want to keep growing this list, so please
            recommend your tool supplier! Email me the details{" "}
            <a href="mailto:info@waxcarvers.com">info@waxcarvers.com</a> and I
            can add it to this list!
          </p>
        </div>
      </div>

      <div className="max-w-prose overflow-auto mx-auto mt-12">
        <nav className="h-full overflow-y-auto" aria-label="Directory">
          {Object.entries(companies).map(([continent, comps]) => (
            <Accordion key={continent} content={{ title: continent }}>
              <ul role="list" className="relative z-0 divide-y divide-gray-200">
                {comps.map((company) => (
                  <CompanyListItem company={company} key={company.name} />
                ))}
              </ul>
            </Accordion>
          ))}
        </nav>
      </div>

      <div className="mt-24">
        <JoinNewsLetterButton />
      </div>
      <ReactTooltip />
    </Layout>
  );
};

export default CastingCompanies;
